import {computed} from "vue"
import {useStore} from "vuex"

const useDocumentos = () => {
  const store = useStore()

  const getDocumentos = async () => {
    return await store.dispatch("documentos/getDocumentos")
  }

  const setSubirDocumento = async (data) => {
    return await store.dispatch("documentos/subirDocumento", data)
  }

  const formDocumentoIni = {
    documento: "",
    archivo: null,
  }

  return {
    formDocumentoIni,
    documentos: computed(() => store.getters["documentos/documentos"]),
    cargando: computed(() => store.getters["documentos/cargando"]),
    subiendo: computed(() => store.getters["documentos/subiendo"]),
    setSubirDocumento,
    getDocumentos,
  }
}

export default useDocumentos
