<template>
  <div class="row">
    <div class="col-md-12 col-sm-12">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table
              class="
                table
                align-middle
                table-row-dashed
                fs-6
                gy-2
                dataTable
                no-footer
              "
              id="kt_ecommerce_category_table"
            >
              <thead>
                <tr
                  class="
                    text-start text-gray-400
                    fw-bold
                    fs-7
                    text-uppercase
                    gs-0
                  "
                >
                  <th class="min-w-250px sorting">Documento</th>
                  <th class="text-center">Descargar</th>
                  <th class="sorting text-center">Estatus</th>
                  <th class="sorting text-center">Fecha de Subida</th>
                  <th class="sorting text-center">Fecha de Vencimiento</th>
                  <th class="text-end min-w-70px sorting_disabled">
                    Subir / Renovar
                  </th>
                </tr>
              </thead>
              <tbody class="fw-semibold text-gray-600">
                <tr v-for="doc in documentos" :key="doc.id">
                  <td>{{ doc.documento }}</td>
                  <td class="text-center">
                    <a
                      v-if="doc.link.length > 0"
                      :href="doc.link"
                      target="_blank"
                      :download="doc.archivo"
                      ><font-awesome-icon icon="download"
                    /></a>
                  </td>
                  <td class="text-center">
                    <div
                      class="badge badge-light-success"
                      :class="[`badge-light-${doc.color}`]"
                    >
                      {{ doc.status }}
                    </div>
                  </td>
                  <td class="text-center">{{ doc.subido }}</td>
                  <td class="text-center">{{ doc.vence }}</td>
                  <td class="text-end">
                    <button
                      class="btn btn-primary btn-sm"
                      type="button"
                      @click="handleDocumento(doc.id)"
                    >
                      Subir documento
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <input
    type="file"
    name="archivo"
    id="archivo"
    class="d-none"
    accept="application/pdf"
    @change="handleCambiarArchivo"
  />
</template>

<script>
import { ref, watchEffect } from "vue";
import useDocumentos from "../composables/useDocumentos";
import Swal from "sweetalert2";
export default {
  setup() {
    const {
      getDocumentos,
      documentos,
      cargando,
      formDocumentoIni,
      subiendo,
      setSubirDocumento,
    } = useDocumentos();
    const formDocumento = ref(formDocumentoIni);

    getDocumentos();

    const handleCambiarArchivo = (event) => {
      const file = event.target.files[0];
      if (file) {
        formDocumento.value.archivo = file;

        Swal.fire({
          title: "Confirmar",
          text: "Se subira el documento, este reemplazará al anterior si existe",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Subir!",
          cancelButtonText: "No, cancelar",
        }).then(async (result) => {
          if (result.isConfirmed) {
            const fp = new FormData();
            fp.append("archivo", formDocumento.value.archivo);
            fp.append("documento", formDocumento.value.documento);

            const result = await setSubirDocumento(fp);
            if (result.ok) {
              getDocumentos();
            }
          }
        });
      }
    };

    const handleDocumento = (id) => {
      formDocumento.value.documento = id;
      document.querySelector("#archivo").click();
    };

    watchEffect(() => documentos.value);
    return { documentos, handleCambiarArchivo, handleDocumento };
  },
};
</script>

<style lang="scss" scoped>
</style>